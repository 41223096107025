import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Suspense,
} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import {
  GetPrivacyApi,
  privacyActions,
  PrivacyApi,
} from "../../../store/Slice/DrawerSlice/DrawerSlice";
import "./Drawer.css";
import {
  Avatar,
  Collapse,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
// Icon Imort

import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MenuIcon from "@mui/icons-material/Menu";
import NoMealsOutlinedIcon from "@mui/icons-material/NoMealsOutlined";
import ClassTwoToneIcon from "@mui/icons-material/ClassTwoTone";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { MdOutlineRoomService } from "react-icons/md";
import { TbReservedLine } from "react-icons/tb";

import ProfileMenu from "./ProfileMenu";
import Footer from "./Footer";
import Loader from "./Loader";
//  End
import appLogo from "../../../Assets/img/LOGO.png";

import PrivacyBackDrop from "./PrivacyBackDrop";
import { MySwal } from "../../../Model/Alert/SuccessAlert";
import { GetSingleGuestData } from "../../../store/Slice/GuestSlice/GuestDataTableSlice";
import { GetApiAdmin } from "../../../store/Slice/AdminProfileSlice/AdminSlice";
const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  left: 0,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("xs")]: {
    left: "-100px",
    border: "5px solid red",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    // Set margin-left to 100px when drawer is closed
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8) + 1,
      width: `calc(100% - ${theme.spacing(8) + 1}px)`,
    },
    // marginLeft: 50,
    // width: `calc(100% - 50px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "0px",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ ChildComponent }) {
  const Token = Cookies.get("Token");
  const id = Cookies.get("userid");
  let profilePic = Cookies.get("image")
    ? `${process.env.REACT_APP_API_URL}/uploads/guests/${Cookies.get("image")}`
    : null;

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [submenuopen, setsubmenuopen] = useState(null);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const Fetchdata = async () => {
      try {
        await dispatch(GetPrivacyApi({ Token }));
        await dispatch(GetApiAdmin({ Token, id }));
      } catch (error) {
        console.error("error", error);
        return error;
      }
    };
    Fetchdata();
  }, [dispatch, Token]);
  const { privacyOpen } = useSelector((state) => state.DrawerStore);
  const { AdminData } = useSelector((state) => state.AdminStore);

  useEffect(() => {
    if (isSmall) {
      setOpen(null);
    }
  }, [isSmall]);

  const handleDrawerOpen = () => {
    if (open) {
      setsubmenuopen(null);
    }
    setOpen(!open);
  };

  const Menudata = useMemo(
    () => [
      {
        id: 1,
        name: "DashBoard",
        link: "/",
        icon: <HomeOutlinedIcon sx={{ fontSize: "2rem" }} />,
      },
      {
        id: 2,
        name: "Guest",
        link: "/guest",
        icon: <PeopleAltOutlinedIcon sx={{ fontSize: "2rem" }} />,
      },
      {
        id: 3,
        name: "All Booking",
        link: "/booking-record",
        icon: <BookOnlineIcon sx={{ fontSize: "2rem" }} />,
      },
      // {
      //   id: 4,
      //   name: "Room Detail",
      //   icon: <ViewInArOutlinedIcon sx={{ fontSize: "2rem" }} />,
      //   submenu: [
      //     {
      //       id: 1,
      //       name: "Rooms",
      //       link: "/rooms",
      //       icon: <BedOutlinedIcon sx={{ fontSize: "2rem" }} />,
      //     },
      //     {
      //       id: 2,
      //       name: "Room Service",
      //       link: "/room-service",
      //       icon: <FastfoodOutlinedIcon sx={{ fontSize: "2rem" }} />,
      //     },
      //     {
      //       id: 3,
      //       name: "Food Items",
      //       link: "/item",
      //       icon: <NoMealsOutlinedIcon sx={{ fontSize: "2rem" }} />,
      //     },
      //     // {
      //     //   id: 4,
      //     //   name: "Food Category",
      //     //   link: "/category",
      //     //   icon: <ClassTwoToneIcon sx={{ fontSize: "2rem" }} />,
      //     // },
      //     // {
      //     //   id: 5,
      //     //   name: "Image Upload",
      //     //   link: "/image-upload",
      //     //   icon: <UploadIcon sx={{ fontSize: "2rem" }} />,
      //     // },
      //   ],
      // },

      {
        id: 4,
        name: "Rooms",
        link: "/rooms",
        icon: <BedOutlinedIcon sx={{ fontSize: "2rem" }} />,
      },
      {
        id: 5,
        name: "Billing",
        link: "/billing",
        icon: <ReceiptOutlinedIcon sx={{ fontSize: "2rem" }} />,
      },
      // {
      //   id: 6,
      //   name: "Reports",
      //   link: "/sales/report",
      //   icon: <SummarizeIcon sx={{ fontSize: "2rem" }} />,
      // },
      {
        id: 6,
        name: "Reports",
        icon: <SummarizeIcon sx={{ fontSize: "2rem" }} />,
        submenu: [
          {
            id: 1,
            name: "Sales Report",
            link: "/sales/report",
            icon: <MonetizationOnIcon sx={{ fontSize: "2rem" }} />,
          },
          {
            id: 2,
            name: "GSTR1 Report",
            link: "/gstr1/report",
            icon: <CurrencyRupeeIcon sx={{ fontSize: "2rem" }} />,
          },
        ],
      },  
      // {
      //   id: 7,
      //   name: "Expenses",
      //   link: "/expense",
      //   icon: <PointOfSaleTwoToneIcon sx={{ fontSize: "2rem" }} />,
      // },
      {
        id: 8,
        name: "Company",
        link: "/company",
        icon: <PeopleAltOutlinedIcon sx={{ fontSize: "2rem" }} />,
      },
    ],
    []
  );

  const handleImgOpen = (privacy) => {
    dispatch(PrivacyApi({ Token, values: { privacy: privacy } }));
    // dispatch(GetPrivacyApi({ Token }));
  };

  const handleImageClose = () => {
    MySwal.fire({
      title: "Enter pincode to unlock",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        const privacy = false;
        dispatch(
          PrivacyApi({
            Token,
            values: { privacy: privacy, password: result.value },
          })
        );
        // dispatch(GetPrivacyApi({ Token }));
      }
    });
  };

  const handlesubmenuclick = useCallback((index) => {
    setsubmenuopen((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const [profileMenuStatus, setprofileMenuStatus] = useState(null);
  const handleProfileMenuOpen = (event) => {
    setprofileMenuStatus(event.currentTarget);
  };
  const handleProfileMenuClose = (e) => setprofileMenuStatus(null);

  // const [NotificationMenuStatus, setNotificationMenuStatus] = useState(null);
  // const handleNotificationMenuOpen = (event) =>
  //   setNotificationMenuStatus(event.currentTarget);
  // const handleNotificationMenuClose = () => setNotificationMenuStatus(null);

  const IsMobile = useMediaQuery("(min-width:900px)");

  // const [isSweetAlertOpen, setIsSweetAlertOpen] = useState(true);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          zIndex: 1,
          background: "#ffffff46",
          //  backgroundColor: isSweetAlertOpen ? 'rgba(255, 0, 0, 0.5)' : '#FFFFFF'
        }}

        //  sx={{
        //   backgroundColor: "#FFFFFF",
        // }}BACKGROUND COLOR
      >
        <Toolbar>
          {open ? (
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 3,
                // ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: 10,
                marginRight: 3,
                // ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box display={"flex"} justifyContent={"end"} width={"100%"}>
            {/* <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignContent={"center"}
              alignItems={"center"}
              spacing={2}
              sx={{ width: "50%" }}
            >
              <TextField
                name="seacrh"
                type="text"
                placeholder="Search Anything"
                size="small"
                sx={{
                  display: IsMobile ? "" : "none",
                }}
              />
            </Stack> */}

            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                gap={3}
                justifyContent={"flex-end"}
                alignContent={"center"}
                alignItems={"center"}
                py={1}
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                <NavLink to={"/rooms"} style={{ textDecoration: "none" }}>
                  <button
                    className="bookingBtn"
                    style={{
                      backgroundColor: "rgb(156,39,176)",
                    }}
                  >
                    Booking
                  </button>
                </NavLink>

                {/* <NavLink to={"/add-service"} style={{ textDecoration: "none" }}>
                  <button
                    className="bookingBtn"
                    style={{ backgroundColor: "rgb(2,136,209)" }}
                  >
                    Room Service
                  </button>
                </NavLink> */}
              </Stack>
              {Boolean(AdminData?.privacy) && (
                <Avatar sx={privacyOpen ? {} : { background: "#fff" }}>
                  <Tooltip title="Privacy">
                    {privacyOpen ? (
                      <IconButton onClick={() => handleImageClose()}>
                        <LockOpenIcon
                          sx={
                            privacyOpen
                              ? { fontSize: "2rem", color: "#fff" }
                              : { fontSize: "2rem" }
                          }
                        />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleImgOpen(true)}>
                        <LockTwoToneIcon sx={{ fontSize: "2rem" }} />
                      </IconButton>
                    )}
                  </Tooltip>
                </Avatar>
              )}
              {/* <Tooltip title="Notification">
                <IconButton
                // onClick={(event) => handleNotificationMenuOpen(event)}
                ></IconButton>
              </Tooltip> */}

              <IconButton onClick={handleProfileMenuOpen}>
                <Avatar src={profilePic} />
              </IconButton>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={"permanent"}
        open={open}
        sx={{
          zIndex: 1,
          background: "rgba(255, 0, 0, 0.5)",
          overflow: "auto",
          "::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      >
        <Box p={3}>
          {open ? (
            <Typography variant="h5">Hotel Booking</Typography>
          ) : (
            <NavLink to={"/"}>
              <Avatar
                src={profilePic}
                sx={{ height: "50px", width: "50px", marginLeft: "-20px" }}
              />
            </NavLink>
          )}
        </Box>
        <Divider />
        <List
          sx={{
            overflow: "auto",
            "::-webkit-scrollbar": {
              width: "0px",
            },
          }}
        >
          {Menudata.map((item, index) => (
            <div key={index}>
              {!item.submenu ? (
                <Tooltip
                  title={!open ? item?.name : ""}
                  placement="right"
                  arrow
                >
                  <ListItemButton
                    component={Link}
                    // onClick={handleDrawerOpen}
                    to={`${item?.link}`}
                    sx={
                      location.pathname === item?.link
                        ? {
                            backgroundImage:
                              "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                            borderRight: "3px solid #8a82fb",
                            "& .MuiListItemIcon-root": {
                              color: "#656CD9",
                            },
                            "&:hover": {
                              backgroundImage:
                                "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                              borderRight: "3px solid #8a82fb",
                              "& .MuiListItemIcon-root": {
                                color: "#656CD9",
                              },
                            },
                          }
                        : {
                            "&:hover": {
                              backgroundImage:
                                "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                              borderRight: "3px solid #8a82fb",
                              "& .MuiListItemIcon-root": {
                                color: "#656CD9",
                              },
                            },
                          }
                    }
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText sx={{ marginLeft: open ? "-20px" : "" }}>
                      {item?.name}
                    </ListItemText>
                  </ListItemButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip
                    title={!open ? item?.name : ""}
                    placement="right"
                    arrow
                  >
                    <ListItemButton
                      onClick={() => {
                        handlesubmenuclick(index);
                      }}
                      sx={{
                        "&:hover": {
                          backgroundImage:
                            "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                          borderRight: "3px solid #8a82fb",
                          "& .MuiListItemIcon-root": {
                            color: "#656CD9",
                          },
                        },
                      }}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        sx={{
                          marginLeft: open ? "-20px" : "",
                        }}
                      >
                        {item?.name}
                      </ListItemText>
                      {submenuopen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                  </Tooltip>
                  <Collapse
                    in={submenuopen === index}
                    timeout={"auto"}
                    unmountOnExit
                  >
                    {item?.submenu &&
                      item?.submenu.map((submenu2, index2) => (
                        <div key={index2}>
                          <ListItem
                            key={index2}
                            sx={{
                              marginTop: "-10px",
                              paddingRight: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            <Tooltip
                              title={!open ? submenu2?.name : ""}
                              placement="right"
                              arrow
                            >
                              <ListItemButton
                                component={Link}
                                to={`${submenu2?.link}`}
                                sx={
                                  location.pathname === submenu2?.link
                                    ? {
                                        backgroundImage:
                                          "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                                        borderRight: "3px solid #8a82fb",
                                        "& .MuiListItemIcon-root": {
                                          color: "#656CD9",
                                        },
                                        "&:hover": {
                                          backgroundImage:
                                            "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                                          borderRight: "3px solid #8a82fb",
                                          "& .MuiListItemIcon-root": {
                                            color: "#656CD9",
                                          },
                                        },
                                      }
                                    : {
                                        "&:hover": {
                                          backgroundImage:
                                            "linear-gradient(to right, rgba(255,0,0,0), rgba(63, 135, 245, 0.5))",
                                          borderRight: "3px solid #8a82fb",
                                          "& .MuiListItemIcon-root": {
                                            color: "#656CD9",
                                          },
                                        },
                                      }
                                }
                              >
                                <ListItemIcon>{submenu2?.icon}</ListItemIcon>
                                <ListItemText>{submenu2?.name}</ListItemText>
                              </ListItemButton>
                            </Tooltip>
                          </ListItem>
                        </div>
                      ))}
                  </Collapse>
                </>
              )}
            </div>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#F7F7F8",
          height: "100vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DrawerHeader />
        <Suspense fallback={<Loader />}>{ChildComponent}</Suspense>
        <Paper
          sx={{
            marginTop: "auto",
            padding: "20px",
            marginBottom: "-23px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Footer />
        </Paper>
      </Box>

      {profileMenuStatus && (
        <ProfileMenu
          open={Boolean(profileMenuStatus)}
          anchorEl={profileMenuStatus}
          handleClose={handleProfileMenuClose}
        />
      )}

      {/* {NotificationMenuStatus && (
        <NotificationList
          BellListopen={Boolean(NotificationMenuStatus)}
          anchorEl={NotificationMenuStatus}
          handleCloseBell={handleNotificationMenuClose}
        />
      )} */}
    </Box>
  );
}
