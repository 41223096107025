import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_Url = process.env.REACT_APP_API_URL + "/admin/login";
const Reset_URL = process.env.REACT_APP_API_URL + "/admin/send/otp";
const Verify_OTP_URL = process.env.REACT_APP_API_URL + "/admin/verify/otp";
const CONFIRM_PD_URL = process.env.REACT_APP_API_URL + "/admin/reset/password";
// import Cookies from "js-cookie"
//%%%%%%%%%% lOGIN API %%%%%%%%%%%%
export const LoginApi = createAsyncThunk("LoginApi", async ({ values }) => {
  const jsondata = JSON.stringify(values);
  const headers = {
    "Content-type": "application/json",
  };
  try {
    const response = await axios.post(Base_Url, jsondata, { headers });
    const {
      token,
      id,
      image,
      default_checkin,
      default_checkout,
      role,
      username,
      privacy_password,
      privacy,
    } = response.data;

    Cookies.set("Token", token, { expires: 2 });
    Cookies.set("userid", id, { expires: 2 });
    Cookies.set("username", username, { expires: 2 });
    Cookies.set("privacy", privacy, { expires: 2 });
    Cookies.set("role", role, { expires: 2 });
    Cookies.set("image", image, { expires: 2 });
    Cookies.set("checkin", default_checkin, { expires: 2 });
    Cookies.set("checkout", default_checkout, { expires: 2 });

    if (response.data.status === true) {
      SuccessAlert(response.data.message);

      setTimeout(() => {
        window.location.pathname = "/";
      }, 1500);
    } else {
      ErrorAlert(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error("error", error);
    // if (error.response.status === 500) {
    ErrorAlert(error.response.data.message);
    // }
    return error.response.data;
  }
});
//%%%%%%%%%% RESET API %%%%%%%%%%%%
export const ResetApi = createAsyncThunk("ResetApi", async (values) => {
  const headers = {
    "Content-type": "application/json",
  };
  try {
    const response = await axios.post(Reset_URL, values, { headers });
    const { time_stamp, user_id, token, email, secret } = response.data.result;
    Cookies.set("temp_token", token, { expires: 1 / 12 });
    Cookies.set("user_id", user_id, { expires: 1 / 12 });
    Cookies.set("time_stamp", time_stamp, { expires: 1 / 12 });
    Cookies.set("secret", secret, { expires: 1 / 12 });
    Cookies.set("email", email, { expires: 1 / 12 });

    if (window.location.pathname !== "/otp") {
      setTimeout(() => {
        window.location.pathname = "/otp";
      }, 1500);
    }
    // Cookies.set("time_stamp",)
    return response.data;
  } catch (error) {
    console.error("error", error);
    // if (error.response.status === 500) {
    ErrorAlert(error.response.data.message);
    // }
    return error.response.data;
  }
});
//%%%%%%%%%% OTP API %%%%%%%%%%%%
export const OTPAPI = createAsyncThunk(
  "OTPAPI",
  async ({ otp, Token, sec_code, time_stamp }) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
      withCredentials: true,
    };
    try {
      const urlQueeryParam = `${Verify_OTP_URL}?sec_code=${sec_code}&time_stamp=${time_stamp} `;
      const response = await axios.post(urlQueeryParam, { otp }, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        Cookies.remove("time_stamp");
        Cookies.remove("email");
        setTimeout(() => {
          window.location.pathname = "/confirm";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      // if (error.response.status === 500) {
      ErrorAlert(error.response.data.message);
      // }
      return error.response.data;
    }
  }
);
//%%%%%%%%%% CHANGE PASSWORD API %%%%%%%%%%%%
export const ChangePasswordApi = createAsyncThunk(
  "ChangePasswordApi",
  async ({ Token, values, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.patch(`${CONFIRM_PD_URL}/${id}`, values, {
        headers,
      });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        setTimeout(() => {
          window.location.pathname = "/login";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("error", error);
      // if (error.response.status === 500) {
      ErrorAlert(error.response.data.message);
      // }
      return error.response.data;
    }
  }
);

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    iserror: null,
    isLoading: false,
    message: "",
    status: null,
  },

  extraReducers: (builder) => {
    //%%%%%%%%%% LOGIN API HANDLE %%%%%%%%%%%%%%%%%%%%
    builder.addCase(LoginApi.rejected, (state, action) => {
      state.iserror = "Somethig Is Error";
      // state.message = action.payload.message;
      // state.status=action.payload.status
    });

    builder.addCase(LoginApi.pending, (state, action) => {
      state.isLoading = true;
      // state.message = action.payload.message;
      // state.status=action.payload.status
    });
    builder.addCase(LoginApi.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%% RESET API HANDLE %%%%%%%%%%%%%%%%%%%%
    builder.addCase(ResetApi.rejected, (state, action) => {
      state.iserror = "Somethig Is Error";
    });
    builder.addCase(ResetApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ResetApi.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%% VERIFY OTP API HANDLE %%%%%%%%%%%%%%%%%%%%
    builder.addCase(OTPAPI.rejected, (state, action) => {
      state.iserror = "Somethig Is Error";
    });
    builder.addCase(OTPAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(OTPAPI.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%% RESET API HANDLE %%%%%%%%%%%%%%%%%%%%
    builder.addCase(ChangePasswordApi.rejected, (state, action) => {
      state.iserror = "Somethig Is Error";
    });
    builder.addCase(ChangePasswordApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ChangePasswordApi.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
  },
});

export default AuthSlice.reducer;
